*{
    font-family: "Courier New", monospace;
}

a:hover, a:visited, a:link, a:active{
    text-decoration: none;
    color: black
}


